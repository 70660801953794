import { cloneDeep } from 'lodash-es'
import type { CreateSettingsPackParams, UpdateSettingsPackParams } from '#settings-pack/types'

export const useCreateSettingsPack = () => {
  const createSettingsPack = async (params: CreateSettingsPackParams) => {
    const { mutate, error } = useAddUpdateSettingsPackMutation({
      variables: {
        input: params,
      },
    })

    const data = await mutate()
    if (error.value) {
      return {
        error: error.value,
      }
    }

    return { data: data?.data?.addUpdateSettingsPack }
  }

  return {
    createSettingsPack,
  }
}

export const useUpdateSettingsPack = () => {
  const { client } = useApolloClient()
  const { getSettingsPackIdentifier, getOptimisticSettingsPackFields } = useSettingsPackDataConvert()

  const updateSettingsPack = async (params: Partial<UpdateSettingsPackParams>) => {
    client.cache.modify({
      id: getSettingsPackIdentifier(params.id as string),
      fields: getOptimisticSettingsPackFields(params),
      optimistic: true,
    })

    const { mutate, error } = useAddUpdateSettingsPackMutation({
      variables: {
        input: params,
      },
    })

    await mutate()
    if (error.value) {
      return {
        error: error.value,
      }
    }
  }

  return {
    updateSettingsPack,
  }
}

export const useToggleSettingsPack = () => {
  const { getSettingsPackIdentifier, getSettingsPackObject } = useSettingsPackDataConvert()

  const toggleAssignSettingsPack = async (id: string, boards: string[]) => {
    const settingsPackIdentifier = getSettingsPackIdentifier(id)
    const settingsPack = getSettingsPackObject(settingsPackIdentifier)
    const currentBoards = cloneDeep(settingsPack?.boards || [])
    const unassignBoards = currentBoards.filter((item) => !boards.includes(item.id)).map(item => item.id)
    const assignBoards = boards.filter((boardId) => !currentBoards.some((board) => board.id === boardId))
    const { mutate, error } = useToggleAssignSettingsPackMutation({
      variables: {
        id,
        boards,
      },
      update: (_, { data }) => {
        if (data?.toggleAssignSettingsPack) {
          const response = data.toggleAssignSettingsPack.settingsPack
          const { statuses, labels, taskTypes } = response
          const { listCache } = useSettingsPackSync()
          const { listCache: taskTypeListCache } = useTaskTypeSync()
          const { listCache: statusListCache } = useStatusSync()
          const { listCache: labelListCache } = useLabelSync()

          Promise.all(unassignBoards.map((boardId) => {
            listCache.delete(boardId, id)
          }))
          Promise.all(assignBoards.map((boardId) => {
            listCache.add(boardId, response)
            for (const status of statuses) {
              statusListCache.add(boardId, status)
            }

            for (const label of labels) {
              labelListCache.add(boardId, label)
            }

            for (const taskType of taskTypes) {
              taskTypeListCache.add(boardId, taskType)
            }
          }))
        }
      }
    })

    const data = await mutate()
    if (error.value) {
      return {
        error: error.value,
      }
    }

    return { data: data?.data?.toggleAssignSettingsPack }
  }

  return {
    toggleAssignSettingsPack,
  }
}

export const useDeleteSettingsPack = () => {
  const { client } = useApolloClient()
  const { getSettingsPackIdentifier } = useSettingsPackDataConvert()

  const deleteSettingsPack = async (id: string) => {
    client.cache.evict({
      id: getSettingsPackIdentifier(id),
    })

    const { mutate, error } = useDeleteSettingsPackMutation(id)

    const data = await mutate()

    if (error.value) {
      return {
        error: error.value,
      }
    }

    return { data: data?.data?.deleteSettingsPack }
  }

  return {
    deleteSettingsPack,
  }
}
